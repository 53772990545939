import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Input,
  Label,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  AutoComplete,
} from '../../../../../UI';
import { CrossIcon } from '../../../../../../assets/icons';
import { useParams } from 'react-router-dom';
import { notify } from '../../../../../common/Alert';
import { AlertDialog, DatePicker } from '../../../../../common';
import {
  ERROR_TITLE,
  SUCCESS_MESSAGE,
  SUCCESS_TITLE,
} from '../../../../../../constants/NotificationConstants';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { createOutcome } from '../../../../../../services/api';
import { SelectInput } from '../../../../../inputs';

interface OutcomesModalProps {
  onClose: () => void;
  showAddOutcome: OutcomeTypes;
  setOutcome: (value: Outcome) => void;
}

const AddOutcomeModal: React.FC<OutcomesModalProps> = ({
  onClose,
  showAddOutcome,
  setOutcome,
}) => {
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );
  const { treatmentCycleId } = useParams();

  const initialOutcomeData: CreateOutcome = {
    treatmentCyclePublicId: treatmentCycleId || '',
    performedByPublicId: user.staff.publicId,
    resultDate: new Date(),
  };
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<CreateOutcome>(initialOutcomeData);
  const [selectedStaff, setSelectedStaff] = useState<StaffInformation>(
    user.staff
  );
  const [showAlertBox, setShowAlertBox] = useState(false);

  const inputHandler = (name: keyof CreateOutcome, value: any) => {
    const errors = { ...formErrors };
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name as string]) delete errors[name as string];
    setFormErrors(errors);
  };

  const isValidInput = useCallback(() => {
    const errors: Record<string, string> = {};
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }, []);

  const handleSubmit = async () => {
    if (!isValidInput()) return;

    if (formData) {
      setIsLoading(true);
      try {
        const [res, error] = await createOutcome(formData);
        if (res) {
          notify.success({
            title: SUCCESS_TITLE.GENERIC_SUCCESS_TITLE,
            message: SUCCESS_MESSAGE.OUTCOME,
          });
          setOutcome(res);
          onClose();
        } else {
          notify.error({
            title: ERROR_TITLE.GENERIC_FAILURE_TITLE,
            message: error.data,
          });
        }
      } catch (error: any) {
        notify.error({
          title: ERROR_TITLE.GENERIC_FAILURE_TITLE,
          message: error,
        });
      } finally {
        setIsLoading(false);
        setShowAlertBox(false);
      }
    }
  };

  useEffect(() => {
    if (showAddOutcome === 'Live Birth') {
      setFormData(prev => ({
        ...prev,
        deliveryDate: new Date(),
      }));
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { deliveryDate, ...newFormData } = formData;
      setFormData(newFormData);
    }
  }, [showAddOutcome]);

  return (
    <>
      <Dialog open={!!showAddOutcome} onOpenChange={onClose}>
        <DialogContent className="p-0 w-[45vw] md:w-[30vw] flex flex-col gap-0 border border-neutral-100 max-h-[80%] ">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-semibold">
              Add Outcome
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={onClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>

          <div className="flex-1 p-4 space-y-4 overflow-scroll">
            <div className="w-full space-y-1">
              <Label className="after:content-['*'] after:text-destructive-500">
                Performed by - staff
              </Label>
              <AutoComplete
                setSelectedValue={values => {
                  setSelectedStaff(values[0]);
                  inputHandler('performedByPublicId', values[0]);
                }}
                selectedValue={[selectedStaff]}
                options={staffs}
                labelField={'fullName'}
                valueField={'publicId'}
                placeholder={'Select Staff'}
                hasError={!!formErrors.staff}
              />
              {formErrors.performedByPublicId && (
                <small className="text-destructive-500">
                  {formErrors.performedByPublicId}
                </small>
              )}
            </div>
            <div className="w-full space-y-1">
              <Label className="after:content-['*'] after:text-destructive-500">
                Result Date
              </Label>
              <DatePicker
                variant="fullDate"
                initialValue={formData.resultDate}
                handleDateChange={value => inputHandler('resultDate', value)}
                hasError={!!formErrors.resultDate}
              />
              {formErrors.resultDate && (
                <small className="text-destructive-500">
                  {formErrors.resultDate}
                </small>
              )}
            </div>
            <hr />

            {showAddOutcome === 'Early Outcome' && (
              <SelectInput
                label={'Early Outcome'}
                name={'earlyOutcome'}
                value={formData?.earlyOutcome || ''}
                options={earlyOutcomeForm.options}
                onValueChange={(value: string) => {
                  const newFormData = {
                    ...formData,
                    earlyOutcome: value,
                  };
                  setFormData(newFormData);
                }}
              />
            )}
            {showAddOutcome === 'Live Birth' && (
              <>
                <SelectInput
                  label="Live Birth"
                  name="liveBirth"
                  value={formData?.liveBirth || ''}
                  options={['Positive', 'Negative']}
                  onValueChange={(value: string) => {
                    const newFormData = {
                      ...formData,
                      liveBirth: value,
                    };
                    setFormData(newFormData);
                  }}
                />

                <div className="flex-1 space-y-1">
                  <Label>Height</Label>
                  <Input
                    value={formData?.height}
                    type="number"
                    placeholder="e.g 40"
                    className="w-full no-spinner"
                    trailingIcon={<>cm</>}
                    onChange={e => inputHandler('height', e.target.value)}
                  />
                </div>
                <div className="flex-1 space-y-1">
                  <Label>Weight</Label>
                  <Input
                    value={formData?.weight}
                    placeholder="e.g 12"
                    type="number"
                    className="w-full no-spinner"
                    trailingIcon={<>kg</>}
                    onChange={e => inputHandler('weight', e.target.value)}
                  />
                </div>
                <div className="w-full space-y-1">
                  <Label className="after:content-['*'] after:text-destructive-500">
                    Delivery Date
                  </Label>
                  <DatePicker
                    variant="dateTime"
                    initialValue={new Date()}
                    handleDateChange={value =>
                      inputHandler('deliveryDate', value)
                    }
                    hasError={!!formErrors.deliveryDate}
                  />
                  {formErrors.deliveryDate && (
                    <small className="text-destructive-500">
                      {formErrors.deliveryDate}
                    </small>
                  )}
                </div>
              </>
            )}
          </div>
          <DialogFooter>
            <Button
              variant={'link'}
              size={'small'}
              className="w-full"
              onClick={onClose}
            >
              Close
            </Button>

            <Button
              size={'small'}
              variant={'primary'}
              onClick={() => setShowAlertBox(true)}
              className="w-full"
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {showAlertBox && (
        <AlertDialog
          type="default"
          actionButtonText={!isLoading ? 'Save' : 'Saving'}
          alertTitle={'Are you sure you want to save this outcome?'}
          onClose={() => setShowAlertBox(false)}
          open={showAlertBox}
          onAction={handleSubmit}
          isActionStarted={isLoading}
        >
          Once saved it cannot be changed.
        </AlertDialog>
      )}
    </>
  );
};

export default AddOutcomeModal;

const earlyOutcomeForm = {
  label: 'Early Outcome',
  name: 'earlyOutcome',
  placeholder: 'Select outcome ',
  options: [
    'GS seen FH seen',
    'Early miscarriage',
    'Missed miscarriage',
    'GS seen, no FH seen',
    'Ectopic',
    'Unknown location',
  ],
};
