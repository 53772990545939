import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Slider,
  Button,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';
import {
  getPartnerHistory,
} from '../../../../../services/api/endPoints/patients';
import { getFullName } from '../../../../../utils/Helper';
import { notify } from '../../../../common/Alert';
import { ERROR_TITLE } from '../../../../../constants/NotificationConstants';
import Skeleton from 'react-loading-skeleton';
import { getReadableDate } from '../../../../../utils/date.utl';

const PartnerHistoryModal = ({
  open,
  onClose,
  patientPublicId,
  currentPatientPartnerId,
}: {
  open: boolean;
  onClose: () => void;
  patientPublicId: string;
  currentPatientPartnerId?: string;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [partners, setPartners] = useState<
    {
      fullName: string;
      publicId: string;
      isCurrent: boolean;
      createdAt: string;
    }[]
  >([]);

  useEffect(() => {
    const fetchPartners = async () => {
      setIsLoading(true);
      const [response] = await getPartnerHistory(patientPublicId);
      if (response) {
        setPartners(
          response.map((ph: any) => ({
            fullName: getFullName(ph.partner),
            publicId: ph.partner.publicId,
            isCurrent: ph.partner.publicId === currentPatientPartnerId,
            createdAt: ph.whenCreated,
          }))
        );
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_FAILURE_TITLE,
          message: 'Failed to fetch partners',
        });
      }
      setIsLoading(false);
    };

    fetchPartners();
  }, []);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0  w-[424px] flex flex-col gap-0 border border-neutral-100 h-[440px]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            Partner History
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          {isLoading ? (
            <>
              {Array.from({ length: 5 }, (_, index) => {
                return (
                  <>
                    <div key={index} className="px-3 py-2">
                      <Skeleton width={300} height={20} />
                      <Skeleton width={100} height={10} />
                    </div>
                  </>
                );
              })}{' '}
            </>
          ) : partners.length > 0 ? (
            partners.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((partner) => (
              <PartnerItem
                key={partner.publicId}
                patientId={partner.publicId}
                name={partner.fullName}
                whenCreated={partner.createdAt}
                isCurrent={partner.isCurrent}
              />
            ))
          ) : (
            <p className="pt-5 text-[14px] text-neutral-900 text-center">
              No partner history found
            </p>
          )}
        </Slider>
      </DialogContent>
    </Dialog>
  );
};

const PartnerItem = ({
  name,
  patientId,
  whenCreated,
  isCurrent,
}: {
  name: string;
  patientId: string;
  whenCreated: string;
  isCurrent: boolean;
}) => (
  <div className="flex justify-between px-3 py-2">
    <div>
      <p className="font-medium text-neutral-900 text-[14px]">{name}</p>
      <p className="text-neutral-500 text-[12px]">{patientId}</p>
    </div>
    <div>
      <p className="text-neutral-500 text-[12px] text-right">As of {getReadableDate(whenCreated)}</p>
      {isCurrent && (
        <p className="text-primary-500 text-[12px] text-right">Current</p>
      )}
    </div>
  </div>
);

export default PartnerHistoryModal;
