import { URL } from '../requestConstructor';

export const getAllObservations = async (publicId: string) => {
  try {
    const response = await URL.get(
      `/observations/get?treatmentCyclePublicId=${publicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const createObservation = async (observation: CreateObservation) => {
  try {
    const response = await URL.post('/observations/create', observation);
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};
