import React, { useCallback, useEffect, useState } from 'react';
import { ActionDropdown, AlertDialog } from '../components/common';
import {
  LeftArrowIcon,
  DownArrowIcon,
  OpenEyeIcon2,
  Pen2Icon,
  OutlinedAddIcon,
  // ShareIcon,
  DeleteIcon,
  // ClockHistoryIcon,
} from '../assets/icons';
import { Button } from '../components/UI';
import {
  PatientProfileInfo,
  PatientJourney,
  PatientInfoTabs,
  AddPartnerModal,
  SharePatientProfileModal,
  AppointmentHistoryModal,
  PatientInfo,
} from '../components/sections';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useFetchPatient from '../hooks/useFetchPatient';
import { getFormattedPatientData, getFullName } from '../utils/Helper';
import PatientInfoPageLoader from '../components/loaders/PatientInfoPageLoader';
import { notify } from '../components/common/Alert';
import { ERROR_MESSAGE, ERROR_TITLE } from '../constants/NotificationConstants';
import { PAGE_TITLE } from '../constants/TitleConstants';
import PatientDetailsModal from '../components/sections/Patient/PatientProfile/PatientDetailsModal';
import AddPatientModal from '../components/sections/Patient/AllPatients/AddPatientModal';
import { CircleMinus, History } from 'lucide-react';
import { updatePatient } from '../services/api/endPoints/patients';
import { THEME } from '../constants/ColorConstants';
import { isString } from 'lodash';
import { useDispatch } from 'react-redux';
import { removePartnerInformation } from '../redux/slice';
import { addPatientInformation } from '../redux/slice/patientInformation';
import PartnerHistoryModal from '../components/sections/Patient/PatientInfoTabs/Actions/PartnerHistoryModal';

const PatientProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetchPatientDetails = useFetchPatient();

  const [patient, setPatient] = useState<PatientInformation>();
  const [partner, setPartner] = useState<PatientInformation>();
  const [openAddPatientModal, setOpenPatientModal] = useState<boolean>(false);
  const [addPartnerModal, setAddPartnerModal] = useState<boolean>(false);
  const [partnerHistoryModal, setPartnerHistoryModal] = useState<boolean>(false);
  const [sharePatientModal, setSharePatientModal] = useState<boolean>(false);
  const [appointmentHistoryModal, setAppointmentHistoryModal] =
    useState<boolean>(false);
  const [patientDetailsModal, setPatientDetailsModal] =
    useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const dispatch = useDispatch();
  const dropdownItems: dropDownMenuItem[] = [
    {
      label: 'Update Profile',
      Icon: <Pen2Icon stroke={THEME.SHADE_100} />,
      action: () => setOpenPatientModal(true),
    },
    {
      label: 'View Details',
      Icon: <OpenEyeIcon2 />,
      action: () => setPatientDetailsModal(true),
    },
    {
      label: 'Add Partner',
      Icon: <OutlinedAddIcon stroke={THEME.SHADE_100} />,
      action: () => setAddPartnerModal(true),
    },
    {
      label: 'Remove Partner',
      Icon: <CircleMinus stroke={THEME.SHADE_100} size={15} />,
      action: () => setDeleteModalOpen(true),
      disabled: !partner,
    },
    {
      label: 'Partner History',
      Icon: <History stroke={THEME.SHADE_100} size={15} />,
      action: () => setPartnerHistoryModal(true),
    },

    // {
    //   label: 'Share Profile',
    //   Icon: <ShareIcon  stroke={THEME.SHADE_100} size={15}/>,
    //   action: () => setSharePatientModal(true),
    // },
    // {
    //   label: 'Appointment history',
    //   Icon: <ClockHistoryIcon />,
    //   action: () => setAppointmentHistoryModal(true),
    // },
    {
      label: <span className="text-destructive-500">Delete</span>,
      Icon: <DeleteIcon stroke={THEME.DESTRUCTIVE_500} />,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      action: () => {},
    },
  ];

  const getPatientDetails = useCallback(async () => {
    if (!id) return;

    setIsLoading(true);
    const [response, error] = await fetchPatientDetails(id, false);

    if (!response) {
      notify.error({
        title: ERROR_TITLE.GENERIC_PATIENT_FETCH,
        message: error,
      });
      setIsLoading(false);
      return;
    }
    setPatient(response);

    if (response.partnerPublicId) {
      const [partnerRes, partnerError] = await fetchPatientDetails(
        response.partnerPublicId,
        true
      );
      if (!partnerRes) {
        notify.error({
          title: ERROR_TITLE.GENERIC_PARTNER_FETCH,
          message: partnerError,
        });
      } else {
        setPartner(partnerRes);
      }
    } else {
      setPartner(undefined);
    }

    setIsLoading(false);
  }, [id]);

  const onBackClick = () => {
    const previousPage = sessionStorage.getItem('patientProfilePreviousPage');
    if (previousPage && previousPage !== 'undefined') {
      navigate(previousPage);
    } else {
      navigate('/patients');
    }
  };

  const deletePatientPartner = async () => {
    if (!patient || !partner) return;
    const data = {
      publicId: patient.publicId,
      partnerPublicId: '',
    };
    setIsDeleting(true);
    const [res, error] = await updatePatient(data);
    if (res) {
      notify.success({
        title: 'Successfully removed partner',
      });
      const updatedPatient: PatientInformation = {
        ...patient,
        partnerPublicId: '',
      };
      setPatient(updatedPatient);
      setPartner(undefined);
      dispatch(removePartnerInformation());
      dispatch(addPatientInformation(updatedPatient));
    } else {
      notify.error({
        title: 'Unable to remove partner',
        message: error.data,
      });
    }
    setIsDeleting(false);
    deleteModalCloseHandler();
  };

  const deleteModalCloseHandler = () => {
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    document.title = `${id} | ${PAGE_TITLE.PATIENT_PROFILE}`;
    setPatient(undefined);
    setPartner(undefined);

    getPatientDetails();
  }, [id]);
  useEffect(() => {
    sessionStorage.setItem('patientProfilePreviousPage', location.state?.from);
  }, [location.state?.from]);
  const onChangeProfilePicture = (profilePictureUrl: string) => {
    if (!patient) return;
    const copiedProfile = { ...patient };
    copiedProfile.profilePictureUrl = profilePictureUrl;
    setPatient(copiedProfile);
  };
  const updatePatientData = async (updatedData: PatientInformation) => {
    setPatient(getFormattedPatientData(updatedData));
    dispatch(addPatientInformation(getFormattedPatientData(updatePatient)));
    if (updatedData.partnerPublicId) {
      const [partnerRes, error] = await fetchPatientDetails(
        updatedData.partnerPublicId,
        true
      );
      if (partnerRes) {
        setPartner(partnerRes);
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_PARTNER_FETCH,
          message:
            !error || !error.data
              ? ERROR_MESSAGE.GENERIC_TRY_AGAIN
              : isString(error)
                ? error
                : error.data,
        });
      }
    }
  };
  return (
    <>
      <AppointmentHistoryModal
        open={appointmentHistoryModal}
        onClose={() => setAppointmentHistoryModal(false)}
      />
      <SharePatientProfileModal
        open={sharePatientModal}
        onClose={() => setSharePatientModal(false)}
      />
      {openAddPatientModal && (
        <AddPatientModal
          open={openAddPatientModal}
          onClose={() => setOpenPatientModal(false)}
          type="Edit"
          patientId={patient?.publicId}
          onUpdate={updatePatientData}
        />
      )}

      {patientDetailsModal && patient && (
        <PatientDetailsModal
          open={patientDetailsModal}
          onClose={() => setPatientDetailsModal(false)}
          patient={patient}
        />
      )}
      {patient && addPartnerModal && (
        <AddPartnerModal
          open={addPartnerModal}
          onClose={() => setAddPartnerModal(false)}
          currentPatientPublicId={patient.publicId}
          fetchNewPatient={getPatientDetails}
          patientPartnerId={patient.partnerPublicId}
        />
      )}
      {patient && partnerHistoryModal && (
        <PartnerHistoryModal
          open={partnerHistoryModal}
          onClose={() => setPartnerHistoryModal(false)}
          patientPublicId={patient.publicId}
          currentPatientPartnerId={patient.partnerPublicId}
        />
      )}
      {partner && deleteModalOpen && (
        <AlertDialog
          actionButtonText="Remove"
          type="delete"
          open={deleteModalOpen}
          onClose={deleteModalCloseHandler}
          onAction={deletePatientPartner}
          alertTitle={`Remove partner - ${getFullName(partner)}`}
          isActionStarted={isDeleting}
        >
          Are you sure you want to remove partner {getFullName(partner)} ?
        </AlertDialog>
      )}
      <div className="space-y-5">
        <div className="flex justify-between bg-white z-50">
          <Button
            variant="outlined"
            leadingIcon={<LeftArrowIcon />}
            className="w-[95px] h-[34px] gap-1"
            onClick={onBackClick}
          >
            Back
          </Button>
          <div className="flex items-center gap-2">
            {patient && (
              <div>
                <p className="font-semibold text-[16px] text-neutral-900">
                  {getFullName(patient)}
                </p>
              </div>
            )}
            {patient && (
              <div>
                <p className="px-1.5 py-0.75 rounded-[20px] border border-primary-500 bg-primary-50 font-medium text-primary-500 inline-block">
                  {patient?.publicId}
                </p>
              </div>
            )}
          </div>
          <ActionDropdown
            side="bottom"
            align="end"
            sideOffset={2}
            drownDownItems={dropdownItems}
          >
            <div>
              <Button
                trailingIcon={<DownArrowIcon />}
                variant={'secondary'}
                className="w-[95px] h-[34px] gap-1"
              >
                Actions
              </Button>
            </div>
          </ActionDropdown>
        </div>
        {!isLoading ? (
          patient && partner ? (
            <div className="flex gap-2">
              <PatientProfileInfo
                patient={patient}
                onChangeProfilePicture={onChangeProfilePicture}
                type="patient"
              />
              <PatientProfileInfo patient={partner} type="partner" />
            </div>
          ) : (
            patient && (
              <PatientInfo
                patient={patient}
                onChangeProfilePicture={onChangeProfilePicture}
              />
            )
          )
        ) : (
          <PatientInfoPageLoader />
        )}

        {/* <BoardLoader /> */}
        <PatientJourney />
        <PatientInfoTabs />
      </div>
    </>
  );
};

export default PatientProfile;
