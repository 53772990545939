import React, { useCallback, useState } from 'react';
import {
  Button,
  Input,
  Label,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  AutoComplete,
} from '../../../../../UI';
import { CrossIcon } from '../../../../../../assets/icons';
import { useParams } from 'react-router-dom';
import { notify } from '../../../../../common/Alert';
import { AlertDialog, DatePicker } from '../../../../../common';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_MESSAGE,
  SUCCESS_TITLE,
} from '../../../../../../constants/NotificationConstants';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { createObservation } from '../../../../../../services/api';
import { SelectInput } from '../../../../../inputs';

interface AddObservationModalProps {
  onClose: () => void;
  showAddObservation: boolean;
  setObservations: (value: Observation) => void;
}

const AddObservationModal: React.FC<AddObservationModalProps> = ({
  onClose,
  showAddObservation,
  setObservations,
}) => {
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );
  const { treatmentCycleId } = useParams();

  const initialObservationData: CreateObservation = {
    treatmentCyclePublicId: treatmentCycleId || '',
    performedByPublicId: user.staff.publicId,
    resultDate: new Date(),
    fetalHeartBeatStatus: 'Negative',
    fetalHeartBeatRate: 0,
  };
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<CreateObservation>(
    initialObservationData
  );
  const [selectedStaff, setSelectedStaff] = useState<StaffInformation>(
    user.staff
  );
  const [showAlertBox, setShowAlertBox] = useState(false);

  const inputHandler = (name: keyof CreateObservation, value: any) => {
    const errors = { ...formErrors };
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name as string]) delete errors[name as string];
    setFormErrors(errors);
  };

  const isValidInput = useCallback(() => {
    const errors: Record<string, string> = {};
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }, []);

  const handleSubmit = async () => {
    if (!isValidInput()) return;

    if (formData) {
      setIsLoading(true);
      try {
        const [res, error] = await createObservation(formData);
        if (res) {
          notify.success({
            title: SUCCESS_TITLE.GENERIC_SUCCESS_TITLE,
            message: SUCCESS_MESSAGE.OBSERVATION,
          });
          setObservations(res);
          onClose();
        } else {
          notify.error({
            title: ERROR_TITLE.GENERIC_FAILURE_TITLE,
            message: error.data || ERROR_MESSAGE.OBSERVATION,
          });
        }
      } catch (error: any) {
        notify.error({
          title: ERROR_TITLE.GENERIC_FAILURE_TITLE,
          message: error.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      } finally {
        setIsLoading(false);
        setShowAlertBox(false);
      }
    }
  };

  return (
    <>
      <Dialog open={!!showAddObservation} onOpenChange={onClose}>
        <DialogContent className="p-0 w-[45vw] md:w-[30vw] flex flex-col gap-0 border border-neutral-100 max-h-[80%] ">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-semibold">
              Add Observation
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={onClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>

          <div className="flex-1 p-4 space-y-4 overflow-scroll">
            <div className="w-full space-y-1">
              <Label className="after:content-['*'] after:text-destructive-500">
                Performed by - staff
              </Label>
              <AutoComplete
                setSelectedValue={values => {
                  setSelectedStaff(values[0]);
                  inputHandler('performedByPublicId', values[0]);
                }}
                selectedValue={[selectedStaff]}
                options={staffs}
                labelField={'fullName'}
                valueField={'publicId'}
                placeholder={'Select Staff'}
                hasError={!!formErrors.staff}
              />
              {formErrors.performedByPublicId && (
                <small className="text-destructive-500">
                  {formErrors.performedByPublicId}
                </small>
              )}
            </div>
            <div className="w-full space-y-1">
              <Label className="after:content-['*'] after:text-destructive-500">
                Result Date
              </Label>
              <DatePicker
                variant="fullDate"
                initialValue={formData.resultDate}
                handleDateChange={value => inputHandler('resultDate', value)}
                hasError={!!formErrors.resultDate}
              />
              {formErrors.resultDate && (
                <small className="text-destructive-500">
                  {formErrors.resultDate}
                </small>
              )}
            </div>
            <hr />

            <SelectInput
              label="Fetal heartbeat status"
              name="fetalHeartBeatStatus"
              value={formData?.fetalHeartBeatStatus}
              options={['Positive', 'Negative']}
              onValueChange={(value: string) => {
                const newFormData = {
                  ...formData,
                  fetalHeartBeatStatus: value,
                };
                setFormData(newFormData);
              }}
            />

            <div className="flex-1 space-y-1">
              <Label>Fetal heartbeat BPM</Label>
              <Input
                value={formData?.fetalHeartBeatRate}
                type="number"
                placeholder="e.g 40"
                className="w-full no-spinner"
                trailingIcon={<>bpm</>}
                onChange={e =>
                  inputHandler('fetalHeartBeatRate', e.target.value)
                }
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              variant={'link'}
              size={'small'}
              className="w-full"
              onClick={onClose}
            >
              Close
            </Button>

            <Button
              size={'small'}
              variant={'primary'}
              onClick={() => setShowAlertBox(true)}
              className="w-full"
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {showAlertBox && (
        <AlertDialog
          type="default"
          actionButtonText={!isLoading ? 'Save' : 'Saving'}
          alertTitle={'Are you sure you want to save this observation?'}
          onClose={() => setShowAlertBox(false)}
          open={showAlertBox}
          onAction={handleSubmit}
          isActionStarted={isLoading}
        >
          Once saved it cannot be changed.
        </AlertDialog>
      )}
    </>
  );
};

export default AddObservationModal;
