import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DataTable, notify } from '../../../../../common';
import { Slider, Button } from '../../../../../UI';
import { LeftArrowIcon, OutlinedAddIcon } from '../../../../../../assets/icons';
import { ColumnDef } from '@tanstack/react-table';
import { getReadableDate } from '../../../../../../utils/date.utl';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
} from '../../../../../../constants/NotificationConstants';
import { getAllObservations } from '../../../../../../services/api';
import AddObservationModal from './AddObservationModal';

const Observations = () => {
  const navigate = useNavigate();
  const [showAddObservation, setShowAddObservation] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [observations, setObservations] = useState<Observation[]>([]);
  const { treatmentCycleId } = useParams();
  const location = useLocation();
  useEffect(() => {
    const getObservations = async () => {
      if (!treatmentCycleId) {
        notify.error({
          title: ERROR_TITLE.GENERIC_ALL_EGG_COLLECTION_FETCH,
          message: 'Treatment cycle not found',
        });
        return;
      }
      setIsLoading(true);
      const [res, error] = await getAllObservations(
        '003277b7-c3c3-47d3-855e-2efc7cf31f41'
      );
      if (res) {
        setObservations(res);
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_ALL_EGG_COLLECTION_FETCH,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
      setIsLoading(false);
    };

    getObservations();
  }, []);

  const observationsColumn = useMemo<ColumnDef<Observation>[]>(
    () => [
      {
        accessorKey: 'publicId',
        header: ({ column }) => {
          return (
            <button
              className="flex gap-1 items-center"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            >
              Public ID
            </button>
          );
        },
      },
      {
        accessorKey: 'fetalHeartBeatStatus',
        header: 'Status',
      },
      {
        accessorKey: 'fetalHeartBeatRate',
        header: 'Heartbeat',
        cell: ({ row }) => {
          return row.original.fetalHeartBeatRate ? (
            <>{row.original.fetalHeartBeatRate} bpm</>
          ) : (
            <>-</>
          );
        },
      },
      {
        accessorKey: 'resultDate',
        header: ({ column }) => {
          return (
            <button
              className="flex gap-1 items-center"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            >
              Result Date
            </button>
          );
        },
        cell: ({ row }) => {
          return <>{getReadableDate(row.original.resultDate)}</>;
        },
      },
      {
        accessorKey: 'performedBy.fullName',
        header: 'Performed by',
      },
    ],
    []
  );

  return (
    <>
      {showAddObservation && (
        <AddObservationModal
          onClose={() => setShowAddObservation(false)}
          showAddObservation={showAddObservation}
          setObservations={observation => {
            setObservations(prev => [...prev, observation]);
          }}
        />
      )}
      <div>
        <div className="p-3 flex justify-between items-center">
          <Button
            variant={'outlined'}
            size={'xmall'}
            leadingIcon={<LeftArrowIcon />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <h1 className="text-neutral-900 font-medium whitespace-nowrap">
            {location?.state?.treatmentCycleName} - Observations
          </h1>
          <div className="flex gap-3">
            <Button
              variant={'primary'}
              leadingIcon={<OutlinedAddIcon />}
              size={'xmall'}
              onClick={() => setShowAddObservation(true)}
              disabled={!!location?.state?.isReadOnly}
            >
              Add Observation
            </Button>
          </div>
        </div>
        <Slider variant={'vertical'} className="h-[500px]">
          <DataTable
            columns={observationsColumn}
            data={observations}
            isLoading={isLoading}
          />
        </Slider>
      </div>
    </>
  );
};

export default Observations;
