import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  Slider,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';
import { getReadableDate } from '../../../../../utils/date.utl';
import RenderData from '../../../../common/RenderData';

const SemenAnalysisViewModal = ({
  open,
  onClose,
  semenAnalysis,
}: SemenAnalysisViewModalProps) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[70vw] md:w-[50vw] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            Semen analysis details
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1 gap-2" variant={'vertical'}>
          <div className="p-3 grid grid-cols-2 gap-3">
            <div className="space-y-2 ">
              <RenderData
                type="single"
                label={'Analysis date'}
                value={getReadableDate(semenAnalysis.analysisDate) ?? '-'}
              />
              <RenderData
                type="single"
                label={'Sample received'}
                value={
                  semenAnalysis.sampleReceivedDate
                    ? getReadableDate(semenAnalysis.sampleReceivedDate)
                    : '-'
                }
              />
              <RenderData
                type="single"
                label={'Patient'}
                value={semenAnalysis.patient?.fullName ?? '-'}
              />
              <RenderData
                type="single"
                label={'Collection location'}
                value={semenAnalysis.collectionLocation ?? '-'}
              />
              <RenderData
                type="single"
                label={'Collection method'}
                value={semenAnalysis.collectionMethod ?? '-'}
              />
            </div>
            <div className="space-y-2">
              <RenderData
                type="single"
                label={'General comments'}
                value={semenAnalysis.generalComments ?? '-'}
              />
              <RenderData
                type="single"
                label={'Exam purpose'}
                value={semenAnalysis.examPurpose ?? '-'}
              />
              <RenderData
                type="single"
                label={'Days of abstinence'}
                value={
                  semenAnalysis.daysOfAbstinence
                    ? semenAnalysis.daysOfAbstinence.toString() + ' days'
                    : '-'
                }
              />
              <RenderData
                type="single"
                label={'Complete sample'}
                value={semenAnalysis?.completeSample ? 'Yes' : 'No'}
              />
              <RenderData
                type="single"
                label={'Sperm source'}
                value={semenAnalysis.spermSource ?? '-'}
              />
            </div>
          </div>
          <hr />
          <p className="text-[20px] font-bold px-3">Analysis</p>
          <div className="p-3 grid grid-cols-2 gap-3">
            <div className="space-y-2">
              <RenderData
                type="single"
                label={'Appearance'}
                value={semenAnalysis.appearance ?? '-'}
              />
              <RenderData
                type="single"
                label={'Liquefaction'}
                value={semenAnalysis.liquefaction ?? '-'}
              />
              <RenderData
                type="single"
                label={'Viscosity'}
                value={semenAnalysis.viscosity ?? '-'}
              />
              <RenderData
                type="single"
                label={'Volume (ml)'}
                value={
                  semenAnalysis.volume ? semenAnalysis.volume.toString() : '-'
                }
              />
              <RenderData
                type="single"
                label={'Concentration'}
                value={
                  semenAnalysis.concentration ? (
                    <p className="flex gap-2">
                      {semenAnalysis.concentration.toString()}{' '}
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        10<sup>6</sup>/ml
                      </p>
                    </p>
                  ) : (
                    '-'
                  )
                }
              />
              <RenderData
                type="single"
                label={'Count'}
                value={
                  semenAnalysis.count ? (
                    <p className="flex gap-2">
                      {semenAnalysis.count.toString()}{' '}
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        M/ejac
                      </p>
                    </p>
                  ) : (
                    '-'
                  )
                }
              />
              <RenderData
                type="single"
                label={'Acidity/basicity'}
                value={
                  semenAnalysis.acidityPh ? (
                    <p className="flex gap-2">
                      {semenAnalysis.acidityPh.toString()}{' '}
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        pH
                      </p>
                    </p>
                  ) : (
                    '-'
                  )
                }
              />
              <RenderData
                type="single"
                label={'Vitality percent'}
                value={
                  semenAnalysis.vitality
                    ? semenAnalysis.vitality.toString() + ' %'
                    : '-'
                }
              />
            </div>
            <div className="space-y-2">
              <RenderData
                type="single"
                label={'Normal morphology'}
                value={
                  semenAnalysis.normalMorphology
                    ? semenAnalysis.normalMorphology.toString() + ' %'
                    : '-'
                }
              />
              <RenderData
                type="single"
                label={'Rapid progressive'}
                value={
                  semenAnalysis.rapidProgressive
                    ? semenAnalysis.rapidProgressive.toString() + ' %'
                    : '-'
                }
              />
              <RenderData
                type="single"
                label={'Slow progressive'}
                value={
                  semenAnalysis.slowProgressive
                    ? semenAnalysis.slowProgressive.toString() + ' %'
                    : '-'
                }
              />
              <RenderData
                type="single"
                label={'Non progressive'}
                value={
                  semenAnalysis.nonProgressive
                    ? semenAnalysis.nonProgressive.toString() + ' %'
                    : '-'
                }
              />
              <RenderData
                type="single"
                label={'Immotile'}
                value={
                  semenAnalysis.immotile
                    ? semenAnalysis.immotile.toString() + ' %'
                    : '-'
                }
              />
              <RenderData
                type="single"
                label={'Round cell count'}
                value={
                  semenAnalysis.roundCellCount ? (
                    <p className="flex gap-2">
                      {semenAnalysis.roundCellCount.toString()}{' '}
                      <p className="text-neutral-500 font-semibold text-[14px]">
                        10<sup>6</sup>/ml
                      </p>
                    </p>
                  ) : (
                    '-'
                  )
                }
              />
              <RenderData
                type="single"
                label={'Agglutination'}
                value={
                  semenAnalysis.agglutination
                    ? semenAnalysis.agglutination.toString() + ' %'
                    : '-'
                }
              />
              <RenderData
                type="single"
                label={'Agglutination type'}
                value={semenAnalysis.agglutinationType ?? '-'}
              />
            </div>
          </div>
        </Slider>
      </DialogContent>
    </Dialog>
  );
};

export default SemenAnalysisViewModal;
