import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ActionDropdown,
  DataTable,
  notify,
} from '../../../../../../components/common';
import { Slider, Button } from '../../../../../../components/UI';
import { LeftArrowIcon, AddIcon } from '../../../../../../assets/icons';
import { ColumnDef } from '@tanstack/react-table';
import { getReadableDate } from '../../../../../../utils/date.utl';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
} from '../../../../../../constants/NotificationConstants';
import { getAllOutcomes } from '../../../../../../services/api';
import OutcomesModal from './AddOutcomeModal';
import { Baby, Clock } from 'lucide-react';

const Outcomes = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [outcomes, setOutcomes] = useState<Outcome[]>([]);
  const navigate = useNavigate();
  const { treatmentCycleId } = useParams();
  const location = useLocation();
  const [showAddOutcome, setShowAddOutcome] = useState<OutcomeTypes>(null);
  useEffect(() => {
    const getOutcomes = async () => {
      if (!treatmentCycleId) {
        notify.error({
          title: ERROR_TITLE.GENERIC_FAILURE_TITLE,
          message: 'Treatment cycle not found',
        });
        return;
      }
      setIsLoading(true);
      const [res, error] = await getAllOutcomes(treatmentCycleId);
      if (res) {
        setOutcomes(res);
      } else {
        notify.error({
          title: ERROR_TITLE.FAILED_TO_GET_OUTCOMES,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
      setIsLoading(false);
    };

    getOutcomes();
  }, []);

  const outcomeColumns = useMemo<ColumnDef<Outcome>[]>(
    () => [
      {
        accessorKey: 'earlyOutcome',
        header: 'Early Outcome',
        cell: ({ row }) => {
          return row.original.earlyOutcome ? (
            <>{row.original.earlyOutcome}</>
          ) : (
            <>-</>
          );
        },
      },
      {
        accessorKey: 'liveBirth',
        header: 'Live Birth',
        cell: ({ row }) => {
          return row.original.liveBirth ? (
            <>{row.original.liveBirth}</>
          ) : (
            <>-</>
          );
        },
      },
      {
        accessorKey: 'height',
        header: 'Height',
        cell: ({ row }) => {
          return row.original.height ? <>{row.original.height} cm</> : <>-</>;
        },
      },
      {
        accessorKey: 'weight',
        header: 'Weight',
        cell: ({ row }) => {
          return row.original.weight ? <>{row.original.weight} kg</> : <>-</>;
        },
      },
      {
        accessorKey: 'deliveryDate',
        header: 'Delivery Date',
        cell: ({ row }) => {
          return row.original.deliveryDate ? (
            <>{getReadableDate(row.original.deliveryDate)}</>
          ) : (
            <>-</>
          );
        },
      },
      {
        accessorKey: 'resultDate',
        header: 'Result Date',
        cell: ({ row }) => {
          return (
            <>
              {row.original.resultDate &&
                getReadableDate(row.original.resultDate)}
            </>
          );
        },
      },
      {
        accessorKey: 'performedBy.fullName',
        header: 'Performed by',
      },
    ],
    []
  );

  const outcomesMenu: dropDownMenuItem[] = [
    {
      label: 'Early outcome',
      Icon: <Clock width={16} />,
      action: () => {
        setShowAddOutcome('Early Outcome');
      },
    },
    {
      label: 'Live birth',
      Icon: <Baby width={18} />,
      action: () => {
        setShowAddOutcome('Live Birth');
      },
    },
  ];
  return (
    <>
      {showAddOutcome && (
        <OutcomesModal
          onClose={() => setShowAddOutcome(null)}
          showAddOutcome={showAddOutcome}
          setOutcome={outcome => setOutcomes(prev => [...prev, outcome])}
        />
      )}
      <div>
        <div className="p-3 flex justify-between items-center">
          <Button
            variant={'outlined'}
            size={'xmall'}
            leadingIcon={<LeftArrowIcon />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <h1 className="text-neutral-900 font-medium whitespace-nowrap">
            {location?.state?.treatmentCycleName} - Outcomes
          </h1>
          <div className="flex gap-3">
            <ActionDropdown
              multipleDropDownSections={[outcomesMenu]}
              side="bottom"
              align="end"
              sideOffset={3}
              alignOffset={18}
            >
              <div>
                <Button
                  variant={'primary'}
                  size={'xmall'}
                  trailingIcon={<AddIcon />}
                >
                  Add Outcome
                </Button>
              </div>
            </ActionDropdown>
          </div>
        </div>
        <Slider variant={'vertical'} className="h-[500px]">
          <DataTable
            columns={outcomeColumns}
            data={outcomes}
            isLoading={isLoading}
          />
        </Slider>
      </div>
    </>
  );
};

export default Outcomes;
