import { URL } from '../requestConstructor';

export const getLatestOutcome = async (publicId: string) => {
  try {
    const response = await URL.get(
      `/outcome/get/latest?treatmentCyclePublicId=${publicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const createOutcome = async (outcome: CreateOutcome) => {
  try {
    const response = await URL.post('/outcome/create', outcome);
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const getAllOutcomes = async (publicId: string) => {
  try {
    const response = await URL.get(
      `/outcome/get?treatmentCyclePublicId=${publicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};
