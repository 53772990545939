import React, { useEffect, useState } from 'react';
import { ActionDropdown, LineGraph, notify } from '../../../../common';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
} from '../../../../../constants/NotificationConstants';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../../UI';
import {
  EndIcon,
  LeftArrowIcon,
  NotePenIcon,
  EggCollectionIcon,
  EmbryoLogyIcon,
  EmbryologyTransferIcon,
  SemenAnalysisIcon,
  AddIcon,
} from '../../../../../assets/icons';
import LoaderIcon from '../../../../../assets/icons/Loader.svg';
import {
  formatToYYYYMMDD,
  getReadableDate,
} from '../../../../../utils/date.utl';
import TreatmentCycleModal from './TreatmentCycleModal';
import EndTreatmentCycleModal from './EndTreatmentCycleModal';
import { PATIENT_ROUTES } from '../../../../../constants/NavigationConstants';
import HormoneDetails from './HormoneDetails';
import {
  getAllHormoneLevel,
  getFollicleScans,
  getLatestObservation,
  getLatestOutcome,
  getTreatmentCycle,
} from '../../../../../services/api';
import FollicleScan from './Scans/FollicleScan/FollicleScan';
import {
  findMaxHormoneResultDate,
  generateTimeLine,
} from '../../../../../utils/Helper';
import { THEME } from '../../../../../constants/ColorConstants';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import MedicationsView from './Views/MedicationView';
import TreatmentOutcome from './TreatmentOutcome';
import AddOutcomeModal from './Outcomes/AddOutcomeModal';
import { Baby, Clock, HeartPulse, NotepadText, View } from 'lucide-react';
import AddObservationModal from './Observations/AddObservationModal';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const TreatmentCycleView = () => {
  const { treatmentCycleId } = useParams();
  const [treatmentCycle, setTreatmentCycle] = useState<TreatmentCycle>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isUpdateTreatmentCycleModalOpen, setIsUpdateTreatmentCycleModalOpen] =
    useState<boolean>(false);
  const [isEndTreatmentCycleModalOpen, setIsEndTreatmentCycleModalOpen] =
    useState<boolean>(false);
  const [timeline, setTimeLine] = useState<TimeLine[]>([]);
  const [hormoneLevels, setHormoneLevels] = useState<HormoneLevel[]>([]);
  const [follicleScans, setFollicleScans] = useState<FollicleScan[]>([]);
  const [dateToHormoneLevelMap, setDateToHormoneLevelMap] = useState<
    Record<string, HormoneLevel>
  >({});
  const [dateToFollicleScanMap, setDateToFollicleScanMap] = useState<
    Record<string, FollicleScan>
  >({});
  const [showAddOutcome, setShowAddOutcome] = useState<OutcomeTypes>(null);
  const [outcome, setOutcome] = useState<Outcome>();
  const [observations, setObservations] = useState<Observation>();
  const [showAddObservation, setShowAddObservation] = useState(false);

  const observationsMenu: dropDownMenuItem[] = [
    {
      label: 'Fetal Heartbeat',
      Icon: <HeartPulse width={16} />,
      action: () => {
        console.log();
      },
      disabled: true,
    },
  ];

  const outcomesMenu: dropDownMenuItem[] = [
    {
      label: 'Early outcome',
      Icon: <Clock width={16} />,
      action: () => {
        setShowAddOutcome('Early Outcome');
      },
    },
    {
      label: 'Live birth',
      Icon: <Baby width={18} />,
      action: () => {
        setShowAddOutcome('Live Birth');
      },
    },
  ];
  useEffect(() => {
    const getTreatmentCycleDetails = async () => {
      if (!treatmentCycleId) {
        notify.error({
          title: ERROR_TITLE.GENERIC_FETCH_TREATMENT_CYCLE,
          message: ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
        return;
      }
      setIsLoading(true);
      const [res, error] = await getTreatmentCycle(treatmentCycleId);
      if (res) {
        setTreatmentCycle(res);
        getHormoneLevels(res.startDate);
        getFollicles();
      } else {
        notify.error({
          title: 'Failed to fetch treatment',
          message: error?.message || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
      setIsLoading(false);
    };
    getTreatmentCycleDetails();
    const getHormoneLevels = async (startDate: string | Date) => {
      if (!treatmentCycleId) return;
      setIsLoading(true);
      const [res] = await getAllHormoneLevel(treatmentCycleId);
      if (res) {
        const maxDate = findMaxHormoneResultDate(res);
        setTimeLine(generateTimeLine(startDate, maxDate));
        setHormoneLevels(res);
        const dateToHormoneLevelMap: Record<string, HormoneLevel> = {};
        res.forEach((hormone: HormoneLevel) => {
          const key = formatToYYYYMMDD(new Date(hormone.resultDate));
          dateToHormoneLevelMap[key] = hormone;
        });
        setDateToHormoneLevelMap(dateToHormoneLevelMap);
      }
      setIsLoading(false);
    };
    const getFollicles = async () => {
      setIsLoading(true);
      const [res] = await getFollicleScans({
        treatmentCyclePublicId: treatmentCycleId,
      });
      if (res) {
        setFollicleScans(res);
        const dateToFollicleMap: Record<string, FollicleScan> = {};
        res.forEach((follicle: FollicleScan) => {
          const key = formatToYYYYMMDD(new Date(follicle.examDate));
          dateToFollicleMap[key] = follicle;
        });
        setDateToFollicleScanMap(dateToFollicleMap);
      }
      setIsLoading(false);
    };

    const getLastOutcome = async (treatmentCycleId: string) => {
      const [res] = await getLatestObservation(treatmentCycleId);
      setObservations(res);
      const [response] = await getLatestOutcome(treatmentCycleId);
      setOutcome(response);
    };

    treatmentCycleId && getLastOutcome(treatmentCycleId);
  }, []);

  const updateTreatmentCycle = (newTreatmentCycle: TreatmentCycle) => {
    setTreatmentCycle(newTreatmentCycle);
  };

  return (
    <>
      {showAddObservation && (
        <AddObservationModal
          onClose={() => setShowAddObservation(false)}
          showAddObservation={showAddObservation}
          setObservations={setObservations}
        />
      )}
      {showAddOutcome && (
        <AddOutcomeModal
          onClose={() => setShowAddOutcome(null)}
          showAddOutcome={showAddOutcome}
          setOutcome={setOutcome}
        />
      )}
      {isUpdateTreatmentCycleModalOpen && treatmentCycle && (
        <TreatmentCycleModal
          updateTreatmentCycle={updateTreatmentCycle}
          type="Edit"
          open={isUpdateTreatmentCycleModalOpen}
          onClose={() => {
            setIsUpdateTreatmentCycleModalOpen(false);
          }}
          treatmentCycle={treatmentCycle}
        />
      )}
      {isEndTreatmentCycleModalOpen && treatmentCycle && (
        <EndTreatmentCycleModal
          updateTreatmentCycle={updateTreatmentCycle}
          open={isEndTreatmentCycleModalOpen}
          onClose={() => {
            setIsEndTreatmentCycleModalOpen(false);
          }}
          treatmentCycle={treatmentCycle}
        />
      )}
      <div className="border-b border-neutral-100 p-3 flex justify-between items-center">
        <Button
          variant={'outlined'}
          size={'xmall'}
          leadingIcon={<LeftArrowIcon />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        {treatmentCycle && (
          <h1 className="whitespace-nowrap text-center space-x-2">
            <span className="text-neutral-900 font-medium ">
              {treatmentCycle.treatment.name}
            </span>
            {treatmentCycle.endDate ? (
              <span className="rounded-xl border border-neutral-500 px-1.5 text-neutral-500  bg-neutral-100">
                Completed
              </span>
            ) : treatmentCycle.cancelledDate ? (
              <span className="rounded-xl border border-destructive-500 bg-destructive-100 px-1.5 text-destructive-500">
                Cancelled
              </span>
            ) : (
              <span className="rounded-xl border border-success-500 bg-success-100 px-1.5 text-success-500">
                Active
              </span>
            )}
          </h1>
        )}
        <div className="flex gap-2">
          <ActionDropdown
            multipleDropDownSections={[observationsMenu, outcomesMenu]}
            side="bottom"
            align="end"
            sideOffset={3}
            alignOffset={18}
          >
            <div>
              <Button
                variant={'primary'}
                size={'xmall'}
                trailingIcon={<AddIcon />}
              >
                Add Outcome
              </Button>
            </div>
          </ActionDropdown>
          <Button
            variant={'primary'}
            size={'xmall'}
            trailingIcon={<NotePenIcon stroke="currentColor" />}
            disabled={!treatmentCycle || isLoading || !!treatmentCycle.endDate}
            onClick={() => setIsUpdateTreatmentCycleModalOpen(true)}
          >
            Edit
          </Button>
          <Button
            variant={'secondary'}
            size={'xmall'}
            onClick={() => setIsEndTreatmentCycleModalOpen(true)}
            disabled={!!treatmentCycle?.endDate || !treatmentCycle || isLoading}
            trailingIcon={<EndIcon />}
          >
            End
          </Button>
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-col justify-center items-center h-[500px]">
          <img src={LoaderIcon} className="w-[40px] h-[40px] animate-spin" />
        </div>
      ) : treatmentCycle ? (
        <div className="min-h-[500px] p-3 space-y-2 overflow-x-scroll">
          <div className="border rounded w-full grid grid-cols-2">
            <div className="border-r p-3 flex flex-col gap-2">
              <div className="grid grid-cols-2">
                <p className="font-semibold text-neutral-500">Treatment</p>
                <p className="font-semibold text-neutral-900">
                  {treatmentCycle.treatment.name}
                </p>
              </div>
              <div className="grid grid-cols-2">
                <p className="font-semibold text-neutral-500">Start date</p>
                <p className="font-semibold text-neutral-900">
                  {getReadableDate(treatmentCycle.startDate)}
                </p>
              </div>
              <div className="grid grid-cols-2">
                <p className="font-semibold text-neutral-500">
                  Last menstruation period (LMP)
                </p>
                <p className="font-semibold text-neutral-900">
                  {treatmentCycle.lastMenstrualPeriod
                    ? getReadableDate(treatmentCycle.lastMenstrualPeriod)
                    : '-'}
                </p>
              </div>
              <div className="grid grid-cols-2">
                <p className="font-semibold text-neutral-500">Services</p>
                <div className="space-y-1">
                  {treatmentCycle.services ? (
                    treatmentCycle.services.map(service => (
                      <p
                        key={service.publicId}
                        className="font-semibold text-neutral-900"
                      >
                        {service.name}
                      </p>
                    ))
                  ) : (
                    <p className="font-semibold text-neutral-900">-</p>
                  )}
                </div>
              </div>
            </div>

            <div className=" p-3 flex flex-col gap-2">
              <div className="grid grid-cols-2">
                <p className="font-semibold text-neutral-500">End date</p>
                <p className="font-semibold text-neutral-900">
                  {treatmentCycle.endDate
                    ? getReadableDate(treatmentCycle.endDate)
                    : '-'}
                </p>
              </div>
              <div className="grid grid-cols-2">
                <p className="font-semibold text-neutral-500">
                  Reason for ending
                </p>
                <p className="font-semibold text-neutral-900">
                  {treatmentCycle.reasonForEnding
                    ? treatmentCycle.reasonForEnding
                    : '-'}
                </p>
              </div>
              <div className="grid grid-cols-2">
                <p className="font-semibold text-neutral-500">Cancel date</p>
                <p className="font-semibold text-neutral-900">
                  {treatmentCycle.cancelledDate
                    ? getReadableDate(treatmentCycle.cancelledDate)
                    : '-'}
                </p>
              </div>
              {(outcome || observations) && (
                <TreatmentOutcome
                  outcome={outcome}
                  observations={observations}
                />
              )}
            </div>
          </div>

          <div className="flex justify-between pt-3 items-center">
            <div className="flex flex-wrap justify-start gap-2 w-full">
              <Button
                variant={'secondary'}
                size={'xmall'}
                trailingIcon={<EggCollectionIcon />}
                disabled={!treatmentCycle || isLoading}
                onClick={() =>
                  navigate(`${PATIENT_ROUTES.EGG_COLLECTION.HOME}`, {
                    state: {
                      isReadOnly:
                        !!treatmentCycle.endDate ||
                        !!treatmentCycle.cancelledDate,
                      treatmentCycleName: treatmentCycle.treatment.name,
                    },
                  })
                }
              >
                Egg Collection
              </Button>
              <Button
                variant={'secondary'}
                size={'xmall'}
                trailingIcon={<EmbryoLogyIcon />}
                disabled={!treatmentCycle || isLoading}
                onClick={() =>
                  navigate(`${PATIENT_ROUTES.EMBRYOLOGY.HOME}`, {
                    state: {
                      isReadOnly:
                        !!treatmentCycle.endDate ||
                        !!treatmentCycle.cancelledDate,
                      treatmentCycleName: treatmentCycle.treatment.name,
                    },
                  })
                }
              >
                Embryology
              </Button>
              <Button
                variant={'secondary'}
                size={'xmall'}
                trailingIcon={<EmbryologyTransferIcon />}
                disabled={!treatmentCycle || isLoading}
                onClick={() =>
                  navigate(
                    `${PATIENT_ROUTES.EMBRYOLOGY.HOME}/${PATIENT_ROUTES.EMBRYOLOGY.EMBRYO_TRANSFER}`,
                    {
                      state: {
                        isReadOnly:
                          !!treatmentCycle.endDate ||
                          !!treatmentCycle.cancelledDate,
                        treatmentCycleName: treatmentCycle.treatment.name,
                      },
                    }
                  )
                }
              >
                Embryo Transfer
              </Button>
              <Button
                variant={'secondary'}
                size={'xmall'}
                trailingIcon={<SemenAnalysisIcon />}
                disabled={!treatmentCycle || isLoading}
                onClick={() =>
                  navigate(`${PATIENT_ROUTES.SEMEN_ANALYSIS.HOME}`, {
                    state: {
                      isReadOnly:
                        !!treatmentCycle.endDate ||
                        !!treatmentCycle.cancelledDate,
                      treatmentCycleName: treatmentCycle.treatment.name,
                    },
                  })
                }
              >
                Semen Analysis
              </Button>
              <Button
                variant={'secondary'}
                size={'xmall'}
                trailingIcon={<View width={16} />}
                disabled={!treatmentCycle || isLoading}
                onClick={() =>
                  navigate(`${PATIENT_ROUTES.OBSERVATIONS.HOME}`, {
                    state: {
                      isReadOnly:
                        !!treatmentCycle.endDate ||
                        !!treatmentCycle.cancelledDate,
                      treatmentCycleName: treatmentCycle.treatment.name,
                    },
                  })
                }
              >
                Observations
              </Button>
              <Button
                variant={'secondary'}
                size={'xmall'}
                trailingIcon={<NotepadText width={16} />}
                disabled={!treatmentCycle || isLoading}
                onClick={() =>
                  navigate(`${PATIENT_ROUTES.OUTCOMES.HOME}`, {
                    state: {
                      isReadOnly:
                        !!treatmentCycle.endDate ||
                        !!treatmentCycle.cancelledDate,
                      treatmentCycleName: treatmentCycle.treatment.name,
                    },
                  })
                }
              >
                Outcomes
              </Button>
            </div>
          </div>
          <MedicationsView />
          <p className="text-[20px] font-bold text-neutral-700 !mt-[30px]">
            Hormone levels
          </p>
          <div className="w-full border border-neutral-100 rounded !mb-[20px]">
            <HormoneLineGraph data={hormoneLevels} timeline={timeline} />
          </div>

          {timeline && hormoneLevels && dateToHormoneLevelMap && (
            <HormoneDetails
              startDate={treatmentCycle.startDate}
              hormoneLevels={hormoneLevels}
              timeline={timeline}
              setTimeLine={setTimeLine}
              dateToHormoneLevelMap={dateToHormoneLevelMap}
              setDateToHormoneLevelMap={setDateToHormoneLevelMap}
              setHormoneLevels={setHormoneLevels}
            />
          )}
          <p className="text-[20px] font-bold text-neutral-700 !mt-[30px]">
            Follicle Scans
          </p>
          <div className="w-full border border-neutral-100 rounded !mb-[20px]">
            <FollicleScanLineGraph data={follicleScans} timeline={timeline} />
          </div>

          {dateToFollicleScanMap && follicleScans && timeline && (
            <FollicleScan
              follicleScans={follicleScans}
              timeline={timeline}
              dateToFollicleScanMap={dateToFollicleScanMap}
              setDateToFollicleScanMap={setDateToFollicleScanMap}
              setFollicleScans={setFollicleScans}
            />
          )}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-[500px]">
          <p className="text-neutral-500">
            No treatment cycle details found. Please try again later
          </p>
        </div>
      )}
    </>
  );
};

export default TreatmentCycleView;

const HormoneLineGraph = ({
  data,
  timeline,
}: {
  data: HormoneLevel[];
  timeline: TimeLine[];
}) => {
  const labels = timeline.map(tl => tl.date);
  const mapDataToDateRange = (hormoneKey: keyof HormoneLevel) => {
    return labels.map(label => {
      const hormoneData = data.find(
        item => formatToYYYYMMDD(new Date(item.resultDate)) === label
      );
      return hormoneData ? (hormoneData[hormoneKey] ?? null) : null;
    });
  };
  const fshData = mapDataToDateRange('fsh');
  const lhData = mapDataToDateRange('lh');
  const e2Data = mapDataToDateRange('e2');
  const p4Data = mapDataToDateRange('p4');

  const e2ChartData = {
    labels,
    datasets: [
      {
        label: 'E2/Estrogen level',
        data: e2Data,
        backgroundColor: THEME.PRIMARY_400,
        borderColor: THEME.PRIMARY_400,
        fill: false,
        tension: 0.1,
        spanGaps: true,
      },
    ],
  };
  const chartData = {
    labels,
    datasets: [
      {
        label: 'FSH level',
        data: fshData,
        backgroundColor: THEME.DESTRUCTIVE_400,
        borderColor: THEME.DESTRUCTIVE_400,
        fill: false,
        tension: 0.1,
        spanGaps: true,
      },
      {
        label: 'Progesterone level',
        data: p4Data,
        backgroundColor: '#818cf8',
        borderColor: '#818cf8',
        fill: false,
        tension: 0.1,
        spanGaps: true,
      },
      {
        label: 'LH level',
        data: lhData,
        backgroundColor: '#c084fc',
        borderColor: '#c084fc',
        fill: false,
        tension: 0.1,
        spanGaps: true,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: ctx => {
            return formatToYYYYMMDD(new Date(ctx[0].parsed.x));
          },
        },
      },
    },

    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
        },
        ticks: {
          display: true,
          callback: value => {
            return formatToYYYYMMDD(new Date(value));
          },
        },
        title: {
          display: false,
        },
      },
      y: {
        title: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          display: false,
        },
      },
    },
  };

  const e2LegendsConfig = [
    {
      name: 'E2/Estrogen level',
      color: THEME.PRIMARY_400,
    },
  ];

  const legendsConfig = [
    {
      name: 'FSH level',
      color: THEME.DESTRUCTIVE_400,
    },
    {
      name: 'Progesterone level',
      color: '#818cf8',
    },
    {
      name: 'LH level',
      color: '#c084fc',
    },
  ];

  return (
    <>
      <LineGraph
        height={400}
        options={options}
        yLabel="E2 Level"
        chartData={e2ChartData}
        legendsConfig={e2LegendsConfig}
      />
      <hr />
      <LineGraph
        height={400}
        options={options}
        yLabel="Hormone levels"
        chartData={chartData}
        legendsConfig={legendsConfig}
      />
    </>
  );
};

const FollicleScanLineGraph = ({
  data,
  timeline,
}: {
  data: FollicleScan[];
  timeline: TimeLine[];
}) => {
  const labels = timeline.map(tl => tl.date);

  const mapDataToDateRange = () => {
    return labels.map(label => {
      const follicleData = data.find(
        item => formatToYYYYMMDD(new Date(item.examDate)) === label
      );

      if (follicleData?.follicles) {
        const leftFollicles = follicleData.follicles.filter(
          follicle => follicle.left
        );
        const rightFollicles = follicleData.follicles.filter(
          follicle => !follicle.left
        );

        const leftAverageThickness = calculateAverageThickness(leftFollicles);
        const rightAverageThickness = calculateAverageThickness(rightFollicles);

        return {
          left: leftAverageThickness,
          right: rightAverageThickness,
        };
      }

      return {
        left: null,
        right: null,
      };
    });
  };

  const follicleData = mapDataToDateRange();
  const leftFollicleData = follicleData.map(data => data.left);
  const rightFollicleData = follicleData.map(data => data.right);

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Right Follicle',
        data: rightFollicleData,
        fill: false,
        backgroundColor: THEME.DESTRUCTIVE_400,
        borderColor: THEME.DESTRUCTIVE_400,
        tension: 0.1,
        spanGaps: true,
      },
      {
        label: 'Left Follicle',
        data: leftFollicleData,
        fill: false,

        backgroundColor: '#818cf8',
        borderColor: '#818cf8',
        tension: 0.1,
        spanGaps: true,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: ctx => {
            return formatToYYYYMMDD(new Date(ctx[0].parsed.x));
          },
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
        },
        ticks: {
          display: true,
          callback: value => {
            return formatToYYYYMMDD(new Date(value));
          },
        },
        title: {
          display: false,
        },
      },
      y: {
        title: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          display: false,
        },
      },
    },
  };

  const legendsConfig = [
    {
      name: 'Right Follicle',
      color: THEME.DESTRUCTIVE_400,
    },
    {
      name: 'Left Follicle',
      color: '#818cf8',
    },
  ];

  return (
    <>
      <LineGraph
        height={400}
        options={options}
        yLabel="Size in mm"
        chartData={chartData}
        legendsConfig={legendsConfig}
      />
    </>
  );
};

const calculateAverageThickness = (follicles: Follicle[]): number => {
  const diameters = follicles.flatMap(follicle => [
    follicle.diameter1,
    follicle.diameter2,
    follicle.diameter3,
  ]);

  const validDiameters = diameters.filter(
    diameter => diameter !== null && diameter !== undefined
  ) as number[];

  const sum = validDiameters.reduce((acc, diameter) => acc + diameter, 0);
  const count = validDiameters.length;

  return count > 0 ? sum / count : 0;
};
