export const ROUTES = {
  ROUTE_TO_BASE: '/',
  ROUTE_TO_SIGN_IN: '/signIn',
  ROUTE_TO_UPDATE_PASSWORD: '/signIn/updatePassword',
  ROUTE_TO_SET_2FA: '/signIn/twoFactorAuth',
  ROUTE_TO_CONFIRM_2FA: '/signIn/confirmTwoFactorAuth',
  ROUTE_TO_OVERVIEW: '/overview',
  ROUTE_TO_UNAUTHORIZED: '/unauthorized',
  ROUTE_TO_PATIENT: '/patients',
  ROUTE_TO_SCANNER: {
    SCANNER: 'documents/scanner',
    PREVIEW: 'preview',
  },
  ROUTE_TO_PATIENT_PROFILE: '/patient/',
  ROUTE_TO_RESOURCE_BOOKING_CALENDAR: '/resource-booking',
  ROUTE_TO_RESOURCE_BOOKING_STAFF_SCHEDULE:
    '/resource-booking/resource-schedule',
  ROUTE_TO_PATIENT_PROFILE_PICTURE_CAMERA: '/take-picture',
  ROUTE_TO_RESOURCE_BOOKING: '/resource-booking',
  LABS: {
    LABS_WILDCARD: '/labs',
    BASE: '/labs',
    CREATE: '/labs/create',
    VIEW: '/labs/view-result',
  },
  ADMIN: {
    BASE: '/admin-clinic-manager',
    STAFFS: 'staffs',
    STAFF_TAKE_PICTURE: 'take-staff-picture',
    SITES: 'sites',
    LOCATIONS: 'locations',
    TEAMS: 'teams',
    PATHWAYS: 'pathways',
    SERVICES: 'services',
    RESOURCES: 'resources',
    TREATMENT: 'treatment',
  },
  EMAIL_MANAGER: '/email-manager',
  CLINIC_BOARD: '/clinic-board',
  SETTINGS: '/settings',
  BOOKING: {
    CONSULTANTS: 'consultants',
    LOCATIONS: 'locations',
  },
};

export const PATIENT_ROUTES = {
  ADMINISTRATIVE_NOTES: 'administrativeNotes',

  MEDICAL_NOTES: 'medicalNotes',

  ADMIN_FORMS: {
    HOME: 'admin-forms',
    CREATE: 'admin-forms/create',
    VIEW: 'admin-forms/view',
  },

  MEDICAL_REPORTS: {
    HOME: 'medical-reports',
    CREATE: 'medical-reports/create',
    VIEW: 'medical-reports/view',
  },

  HANDWRITTEN_NOTES: {
    HOME: 'handwritten-notes',
    CREATE: 'handwritten-notes/create',
  },

  RECOMMENDED: {
    HOME: 'recommended',
  },

  APPOINTMENT: {
    HOME: 'appointment',
    VIEW: 'appointment/view',
  },

  TREATMENT_CYCLE: {
    HOME: 'treatment-cycle',
    VIEW: 'treatment-cycle/view',
  },

  EGG_COLLECTION: {
    HOME: 'egg-collection',
  },

  SEMEN_ANALYSIS: {
    HOME: 'semen-analysis',
  },

  OBSERVATIONS: {
    HOME: 'observations',
  },

  OUTCOMES: {
    HOME: 'outcomes',
  },

  EMBRYOLOGY: {
    HOME: 'embryology',
    EMBRYO_TRANSFER: 'embryo-transfer',
  },

  LABS: {
    HOME: 'labs',
    ADD: 'labs/add-test',
    VIEW: 'labs/view-result',
  },

  PRESCRIPTION: {
    HOME: 'prescription',
    CREATE: 'prescription/create',
    VIEW: 'prescription/view',
  },

  DOCUMENTS: {
    HOME: 'documents',
    VIEW: 'documents/view',
  },

  NOTES: {
    HOME: 'notes',
  },
};

export const SIDEBAR_MENU_CONSTANT = {
  OVERVIEW: 'Overview',
  PATIENTS: 'Patients',
  LABS: 'Labs',
  CLINIC_BOARD: 'Clinic Board',
  RESOURCE_BOOKING_MENUS: {
    RESOURCE_BOOKING: 'Resource Booking',
    CALENDAR: 'Calendar',
    SCHEDULE: 'Schedule',
  },
  ADMIN_MENUS: {
    ADMIN: 'Admin',
    CLINIC_MANAGER: 'Clinic Manager',
    EMAIL_MANAGER: 'Email Manager',
    SETTINGS: 'Settings',
  },
  SIGN_OUT: 'Sign Out',
};
