import React from 'react';
import { getAppointmentReadableDateTime } from '../../../../../utils/date.utl';
interface TreatmentOutcomeProps {
  outcome?: Outcome;
  observations?: Observation;
}
const TreatmentOutcome: React.FC<TreatmentOutcomeProps> = ({
  outcome,
  observations,
}) => {
  return (
    <>
      {observations?.fetalHeartBeatRate && (
        <div className="grid grid-cols-2">
          <p className="font-semibold text-neutral-500">Fetal heartbeat</p>
          <p className="font-semibold text-neutral-900">
            {observations?.fetalHeartBeatRate + ' bpm'}
          </p>
        </div>
      )}
      {outcome?.earlyOutcome && (
        <div className="grid grid-cols-2">
          <p className="font-semibold text-neutral-500">Early outcome</p>
          <p className="font-semibold text-neutral-900">
            {outcome?.earlyOutcome}
          </p>
        </div>
      )}
      {outcome?.liveBirth && (
        <div className="grid grid-cols-2">
          <p className="font-semibold text-neutral-500">Live Birth</p>
          <p className="font-semibold text-neutral-900">{outcome?.liveBirth}</p>
        </div>
      )}
      {outcome?.deliveryDate && (
        <div className="grid grid-cols-2">
          <p className="font-semibold text-neutral-500">Delivery Date</p>
          <p className="font-semibold text-neutral-900">
            {getAppointmentReadableDateTime(new Date(outcome?.deliveryDate))}
          </p>
        </div>
      )}
      {outcome?.height && (
        <div className="grid grid-cols-2">
          <p className="font-semibold text-neutral-500">Height</p>
          <p className="font-semibold text-neutral-900">{outcome?.height} CM</p>
        </div>
      )}
      {outcome?.weight && (
        <div className="grid grid-cols-2">
          <p className="font-semibold text-neutral-500">Weight</p>
          <p className="font-semibold text-neutral-900">{outcome?.weight} KG</p>
        </div>
      )}
    </>
  );
};

export default TreatmentOutcome;
