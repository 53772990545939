import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store/store';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ActionDropdown,
  AlertDialog,
  DataTable,
  notify,
} from '../../../../common';
import { Button, Slider } from '../../../../UI';
import {
  DeleteIcon,
  DirectedArrowIcon,
  DotMenu,
  LeftArrowIcon,
  NotePenIcon,
  OpenEyeIcon2,
  OutlinedAddIcon,
} from '../../../../../assets/icons';
import { ColumnDef } from '@tanstack/react-table';
import { THEME } from '../../../../../constants/ColorConstants';
import { getReadableDate } from '../../../../../utils/date.utl';
import {
  getAllSemenAnalyses,
  deleteSemenAnalysis,
} from '../../../../../services/api/endPoints/semenAnalysis';
import { useDispatch } from 'react-redux';
import {
  addSemenAnalyses,
  deleteSemenAnalysis as deleteSemenAnalysisRedux,
} from '../../../../../redux/slice';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import SemenAnalysisModal from './SemenAnalysisModal';
import SemenAnalysisViewModal from './SemenAnalysisViewModal';

const SemenAnalyses = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { semenAnalyses } = useSelector<RootState, SemenAnalysisState>(
    state => state.semenAnalysis
  );
  const [selectedSemenAnalysis, setSelectedSemenAnalysis] =
    useState<SemenAnalysis | null>(null);
  const navigate = useNavigate();
  const { id: patientId, treatmentCycleId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [addSemenAnalysisModalOpen, setAddSemenAnalysisModalOpen] =
    useState<boolean>(false);
  const [updateSemenAnalysisModalOpen, setUpdateSemenAnalysisModalOpen] =
    useState<boolean>(false);
  const [deleteSemenAnalysisModalOpen, setDeleteSemenAnalysisModalOpen] =
    useState<boolean>(false);
  const [viewSemenAnalysisModalOpen, setViewSemenAnalysisModalOpen] =
    useState<boolean>(true);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  useEffect(() => {
    const getSemenAnalyses = async () => {
      if (!treatmentCycleId) {
        notify.error({
          title: ERROR_TITLE.GENERIC_ALL_SEMEN_ANALYSIS_FETCH,
          message: 'Treatment cycle not found',
        });
        return;
      }
      setIsLoading(true);
      const [res, error] = await getAllSemenAnalyses(treatmentCycleId);
      if (res) {
        dispatch(addSemenAnalyses(res));
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_ALL_SEMEN_ANALYSIS_FETCH,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
      setIsLoading(false);
    };
    getSemenAnalyses();
  }, []);

  const semenAnalysisColumn: ColumnDef<SemenAnalysis>[] = [
    {
      accessorKey: 'publicId',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Analysis ID
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
              </div>
            ) : (
              <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
            )}
          </button>
        );
      },
      cell: ({ row }) => (
        <>
          <span>{row.original.publicId}</span>
          <br />
          <span className="px-1.5 py-0.75 rounded-[20px] border border-primary-500 bg-primary-50 font-medium text-primary-500 inline-block">
            {row.original.patient.publicId === patientId
              ? 'Patient'
              : 'Partner'}
          </span>
        </>
      ),
    },
    {
      accessorKey: 'analysisDate',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Analysis Date
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
              </div>
            ) : (
              <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
            )}
          </button>
        );
      },
      cell: ({ row }) => {
        return <>{getReadableDate(row.original.analysisDate)}</>;
      },
    },
    {
      accessorKey: 'analysedBy.fullName',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Analysed by
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
              </div>
            ) : (
              <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
            )}
          </button>
        );
      },
    },
    {
      id: 'action',
      cell: ({ row }) => {
        const dropDownMenu: dropDownMenuItem[] = [
          {
            label: 'Delete',
            Icon: <DeleteIcon stroke={THEME.DESTRUCTIVE_500} />,
            disabled: !!location?.state?.isReadOnly,
            action: () => {
              setSelectedSemenAnalysis(row.original);
              setDeleteSemenAnalysisModalOpen(true);
            },
          },
        ];
        return (
          <div className="flex justify-end gap-8">
            <div className="flex gap-2">
              <Button
                variant={'outlined'}
                className="h-[30px]"
                size={'xmall'}
                trailingIcon={<OpenEyeIcon2 />}
                isLoading={false}
                onClick={() => {
                  setSelectedSemenAnalysis(row.original);
                  setViewSemenAnalysisModalOpen(true);
                }}
              >
                View
              </Button>
              <Button
                variant={'outlined'}
                className="h-[30px]"
                size={'xmall'}
                trailingIcon={<NotePenIcon stroke="currentColor" />}
                isLoading={false}
                onClick={() => {
                  setSelectedSemenAnalysis(row.original);
                  setUpdateSemenAnalysisModalOpen(true);
                }}
                disabled={!!location?.state?.isReadOnly}
              >
                Edit
              </Button>
              <ActionDropdown
                drownDownItems={dropDownMenu}
                side="bottom"
                align="end"
                sideOffset={3}
                alignOffset={18}
              >
                <button className="w-8 h-8 p-0 outline-none border-none">
                  <span className="sr-only">Open menu</span>
                  <DotMenu />
                </button>
              </ActionDropdown>
            </div>
          </div>
        );
      },
    },
  ];

  const closeDeleteModalHandler = () => {
    setIsDeleting(false);
    setSelectedSemenAnalysis(null);
    setDeleteSemenAnalysisModalOpen(false);
  };

  const deleteSemenAnalysisHandler = async () => {
    if (!selectedSemenAnalysis) return;
    setIsDeleting(true);
    const [, error] = await deleteSemenAnalysis(selectedSemenAnalysis.publicId);
    if (!error) {
      notify.success({
        title: SUCCESS_TITLE.GENERIC_SEMEN_ANALYSIS_DELETE,
        message: `Semen analysis '${selectedSemenAnalysis.publicId}' has been deleted`,
      });
      dispatch(
        deleteSemenAnalysisRedux({ publicId: selectedSemenAnalysis.publicId })
      );
      closeDeleteModalHandler();
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_SEMEN_ANALYSIS_DELETE,
        message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
      });
      setIsDeleting(false);
    }
  };

  return (
    <>
      {addSemenAnalysisModalOpen && (
        <SemenAnalysisModal
          type="Create"
          open={addSemenAnalysisModalOpen}
          onClose={() => setAddSemenAnalysisModalOpen(false)}
        />
      )}
      {updateSemenAnalysisModalOpen && selectedSemenAnalysis && (
        <SemenAnalysisModal
          type="Edit"
          semenAnalysis={selectedSemenAnalysis}
          open={updateSemenAnalysisModalOpen}
          onClose={() => {
            setSelectedSemenAnalysis(null);
            setUpdateSemenAnalysisModalOpen(false);
          }}
        />
      )}
      {viewSemenAnalysisModalOpen && selectedSemenAnalysis && (
        <SemenAnalysisViewModal
          open={viewSemenAnalysisModalOpen}
          semenAnalysis={selectedSemenAnalysis}
          onClose={() => {
            setViewSemenAnalysisModalOpen(false);
            setSelectedSemenAnalysis(null);
          }}
        />
      )}
      {deleteSemenAnalysisModalOpen && selectedSemenAnalysis && (
        <AlertDialog
          type="delete"
          open={deleteSemenAnalysisModalOpen}
          onClose={closeDeleteModalHandler}
          onAction={deleteSemenAnalysisHandler}
          alertTitle={`Deleting semen analysis '${selectedSemenAnalysis.publicId}'`}
          isActionStarted={isDeleting}
          actionButtonText={isDeleting ? 'Deleting' : 'Delete'}
        >
          Are you sure you want to delete semen analysis '
          {selectedSemenAnalysis.publicId}'?
        </AlertDialog>
      )}
      <div>
        <div className="p-3 flex justify-between items-center">
          <Button
            variant={'outlined'}
            size={'xmall'}
            leadingIcon={<LeftArrowIcon />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <h1 className="text-neutral-900 font-medium whitespace-nowrap">
            {location?.state?.treatmentCycleName} - Semen Analysis
          </h1>
          <div className="flex gap-3">
            <Button
              variant={'primary'}
              leadingIcon={<OutlinedAddIcon />}
              size={'xmall'}
              onClick={() => setAddSemenAnalysisModalOpen(true)}
              disabled={!!location?.state?.isReadOnly}
            >
              Add Semen Analysis
            </Button>
          </div>
        </div>
        <Slider variant={'vertical'} className="h-[500px]">
          <DataTable
            columns={semenAnalysisColumn}
            data={semenAnalyses}
            isLoading={isLoading}
          />
        </Slider>
      </div>
    </>
  );
};

export default SemenAnalyses;
